import React, { FC } from 'react'

import { classNames } from 'app/util'

interface ColProps {
  className?: string
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
}

export const Col: FC<ColProps> = ({children, className, ...sizes}) => {
  const colClasses = classNames(
    sizes.xs && `col-${sizes.xs}`,
    sizes.sm && `col-sm-${sizes.sm}`,
    sizes.md && `col-md-${sizes.md}`,
    sizes.lg && `col-lg-${sizes.lg}`,
    sizes.xl && `col-xl-${sizes.xl}`
  )
  const classes = classNames(colClasses || 'col', className)
  return (
    <div className={classes}>
      {children}
    </div>
  )
}