import React, { FC } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import { Home } from 'app/pages/Home'
import { Layout } from './Layout'

const About = React.lazy(() => import('app/pages/About'))
const Budget = React.lazy(() => import('app/pages/Budget'))
const Work = React.lazy(() => import('app/pages/Work'))

export const Routes: FC = () => {
  const match = useRouteMatch()
  const path = (path: string) => `${match.path}${path}`.replace('//', '/')
  return (
    <Layout>
      <React.Suspense fallback={<div />}>
        <Switch>
          <Route path={path('/')} exact component={Home} />
          <Route path={path('/about')} component={About} />
          <Route path={path('/budget')} component={Budget} />
          <Route path={path('/work')} component={Work} />
          <Redirect to={match.url} />
        </Switch>
      </React.Suspense>
    </Layout>
  )
}