import React, { FC, createContext, useContext, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

interface FormGroupProps {
  label?: string
}

let uniqueId = 0

const context = {
  id: '',
  placeholder: ''
}

const FormGroupContext = createContext(context)

export function useFormGroupContext() {
  return useContext(FormGroupContext)
}

export const FormGroup: FC<FormGroupProps> = ({children, label}) => {
  const id = useRef(`form-input-${++uniqueId}`)
  const context = {
    id: id.current,
    placeholder: `${label}.placeholder`
  }
  return (
    <div className="form-group row">
      {label && (
        <label className="col-sm-3 col-form-label" htmlFor={id.current}>
          <FormattedMessage id={`${label}.label`} />
        </label>
      )}
      <FormGroupContext.Provider value={context}>
        {children}
      </FormGroupContext.Provider>
    </div>
  )
}