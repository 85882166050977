import React, { ChangeEvent, FC, useState } from 'react'

import { useFormGroupContext } from './FormGroup'

interface TextAreaProps {
  name: string
  onChange?: (_: {[key: string]: string}) => void
}

export const TextArea: FC<TextAreaProps> = ({name, onChange}) => {
  const context = useFormGroupContext()
  const [ value, setValue ] = useState('')
  const props = {
    onChange: (event: ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value)
      onChange?.({[name]: event.target.value})
    },
    id: context.id,
    name: name,
    value: value
  }
  return (
    <div className="col-sm-9">
      <textarea className="form-control" {...props} />
    </div>
  )
}