import React, { FC } from 'react'
import { IntlProvider } from 'react-intl'
import { Redirect, Route, RouteChildrenProps, Switch } from 'react-router-dom'

import { messages } from './messages'

interface IntlRenderProps {
  lang: string
}

const IntlRender: FC<RouteChildrenProps<IntlRenderProps>> = ({children, match}) => {
  const lang = match?.params?.lang || navigator.language.slice(0, 2)
  const languages = Object.keys(messages)
  if (languages.includes(lang)) {
    return (
      <IntlProvider locale={lang} messages={messages[lang]}>
        {children}
      </IntlProvider>
    )
  } else {
    return (
      <Redirect to="/" />
    )
  }
}

export const Intl: FC = ({children}) => {
  const routeChildren: typeof IntlRender = props => (
    <IntlRender {...props} children={children} />
  )
  return (
    <Switch>
      <Route path="/:lang([a-z]{2})" children={routeChildren} />
      <Route path="/" children={routeChildren} />
      <Redirect to="/" />
    </Switch>
  )
}