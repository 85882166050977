import React, { FC } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { Intl } from './app/components/intl'
import { Routes } from './app/components/router'

export const App: FC = () => (
  <BrowserRouter>
    <Intl>
      <Routes />
    </Intl>
  </BrowserRouter>
)