import React, { FC, FormEvent, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Card, CardBody, Col, FormGroup, Input, Row, TextArea } from 'app/components/bootstrap'
import { IconArrowRight, IconCheckCircleFill } from 'app/components/bootstrap-icon'
import { classNames } from 'app/util/class-names'

import classes from './Contact.module.scss'

const state = {
  name: '',
  email: '',
  message: ''
}

export const Contact: FC = () => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState<Null<boolean>>(null)
  const [value, setValue] = useState(state)
  const handleChange = (input: Partial<typeof state>) => {
    setValue(value => ({...value, ...input}))
  }
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const request = {
      headers: new Headers([['Content-Type', 'application/json']]),
      method: 'POST',
      body: JSON.stringify(value)
    }
    setLoading(true)
    fetch(`/api/talkToRaks`, request)
      .then(response => setSuccess(response.ok))
      .catch(() => alert('Falha ao enviar informações!'))
      .then(() => setLoading(false))
      .then(() => window.location.assign('http://raks-com-br.rds.land/typ-home'))
  }
  return (
    <div className={classes.root}>
      <Row maxWidth>
        <Col md={4} lg={5} xl={6}>
          <p className={classes.title}>
            <FormattedMessage id="contact.title" />
          </p>
          <p className={classes.text}>
            <FormattedMessage id="contact.text_1" />
          </p>
          <p className={classes.text}>
            <FormattedMessage id="contact.text_2" />
          </p>
        </Col>
        <Col md={8} lg={7} xl={6}>
          <Card>
            <CardBody>
              <form className={classes.form} onSubmit={handleSubmit}>
                <FormGroup label="contact.name">
                  <Input name="name" onChange={handleChange} />
                </FormGroup>
                <FormGroup label="contact.email">
                  <Input type="email" name="email" onChange={handleChange} />
                </FormGroup>
                <FormGroup label="contact.phone">
                  <Input type="tel" name="phone" onChange={handleChange} />
                </FormGroup>
                <FormGroup label="contact.message">
                  <TextArea name="message" onChange={handleChange} />
                </FormGroup>
                <div className={classes.submit}>
                  <div className={classNames(classes.loader, loading && classes.loading)}>
                    <div className="spinner-border text-success" />
                  </div>
                  <button type="submit" className="btn btn-success" disabled={loading}> 
                    <FormattedMessage id="contact.submit" /> <IconArrowRight />
                  </button>
                </div>
                <div className={classNames(classes.success, success && classes.visible)}>
                  <span className={classes.icon}>
                    <IconCheckCircleFill />
                  </span>
                  <span className={classes.text}>
                    Informações enviadas com sucesso!
                  </span>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}