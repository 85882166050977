type ClassName = string | number | false | undefined | null | ClassList
type ClassList = ClassName[]

export function classNames(...classes: ClassList) {
  const className: string = classes.reduce<string>((current, classes) => {
    if (Array.isArray(classes)) {
      return `${current} ${classNames(classes)}`
    } else if (typeof classes === 'string') {
      return `${current} ${classes}`
    } else {
      return current
    }
  }, '')
  return className.replace(/\s\s+/g, ' ')
}