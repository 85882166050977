import React, { ChangeEvent, FC } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { useFormGroupContext } from './FormGroup'

interface FileInputProps {
  accept: string
  multiple?: boolean
  name: string
  onChange?: (_: {[key: string]: string[] | string | null}) => void
}

export const FileInput: FC<FileInputProps> = ({name, onChange, multiple, ...props}) => {
  const context = useFormGroupContext()
  const intl = useIntl()
  const params = {
    onChange: (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const files = Array.from(event.target.files)
        const value: string[] = []
        files.forEach((file, i) => {
          const reader = new FileReader()
          reader.onloadend = (event: ProgressEvent<FileReader>) => {
            if (event.target) {
              if (multiple) {
                value.splice(i, 0, event.target.result as string)
                onChange?.({[name]: value})
              } else {
                onChange?.({[name]: event.target.result as string})
              }
            }
          }
          reader.readAsDataURL(file)
        })
      } else {
        onChange?.({[name]: null})
      }
    },
    id: context.id,
    multiple: multiple,
    name: name,
    placeholder: context.placeholder && intl.formatMessage({id: context.placeholder}),
    lang: intl.locale,
  }
  return (
    <div className="col-sm-9">
      <div className="custom-file">
        <input className="custom-file-input" type="file" {...params} {...props} />
        <label className="custom-file-label" htmlFor={context.id}>
          <FormattedMessage id="form.file.browse" />
        </label>
      </div>
    </div>
  )
}