import React, { FC } from 'react'
import { ReactComponent as br } from 'svg-country-flags/svg/br.svg'
import { ReactComponent as de } from 'svg-country-flags/svg/de.svg'
import { ReactComponent as us } from 'svg-country-flags/svg/us.svg'

import classes from './Flag.module.scss'

interface FlagProps {
  country: 'br' | 'de' | 'us'
}

const flags = {br, de, us}

export const Flag: FC<FlagProps> = ({country}) => {
  const Component = flags[country]
  return (
    <Component className={classes.root} />
  )
}