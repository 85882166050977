import React, { useState } from 'react'

import { FaIcon } from 'app/components/icon'
import { classNames } from 'app/util/class-names'

import classes from './LazyFrame.module.scss'

interface LazyFrameProps {
  preview: string
  video: string
  title: string
}

export const LazyFrame: React.FC<LazyFrameProps> = ({preview, video, title}) => {
  const [loaded, setLoaded] = useState(false)
  const [play, setPlay] = useState(false)
  const iframe = {
    allowFullScreen: true,
    className: classes.frame,
    frameBorder: 0,
    onLoad: () => setLoaded(true),
    src: `https://www.youtube.com/embed/${video}?autoplay=1&modestbranding=1`
  }
  return (
    <div className={classNames(classes.container, loaded && classes.loaded)}>
      <div className={classes.preview} onClick={() => setPlay(true)}>
        <img className={classNames(classes.preview)} src={preview} alt={title} />
        <FaIcon className={classes.icon} icon="youtube" />
      </div>
      {play && (
        <iframe {...iframe} title={title} />
      )}
    </div>
  )
}