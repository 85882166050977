import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, LinkProps, useLocation } from 'react-router-dom'

import { Container } from 'app/components/bootstrap'
import { Flag } from 'app/components/icon'
import { classNames } from 'app/util'

import classes from './Navbar.module.scss'

interface NavbarProps {
  className?: string
  hideLogo?: boolean
}

const ScrollTopLink: FC<LinkProps> = props => {
  const handleClick = () => window.scroll({top: 0, behavior: 'smooth'})
  return (
    <Link onClick={handleClick} {...props} />
  )
} 

export const Navbar: FC<NavbarProps> = ({className}) => {
  const location = useLocation()
  const intl = useIntl()
  const [navClass, setNavClass] = useState('')
  const url = (url: string) => `/${intl.locale}/${url}`.replace('//', '/')
  const languageUrl = (lang: string) => {
    if (location.pathname.startsWith('/pt') || location.pathname.startsWith('/en')) {
      return `/${lang}/${location.pathname.slice(3)}`.replace('//', '/')
    } else {
      return `/${lang}${location.pathname}`
    }
  }
  const anchor = (url: string) => ({
    href: url,
    rel: 'noopener noreferrer',
    target: '_blank'
  })
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 64) {
        setNavClass(classes.background)
      } else {
        setNavClass('')
      }
    }
    document.addEventListener('scroll', handleScroll)
    return () => document.removeEventListener('scroll', handleScroll)
  })
  return (
    <div className={classNames(classes.root, className)}>
      <nav id="main-navbar" className={classNames(classes.nav, navClass)}>
        <Container className="text-right">
          <Link className="btn btn-outline-secondary ml-2" to={{pathname: url('/'), hash: 'home-product'}}>
            <FormattedMessage id="navbar.links.product" />
          </Link>
          <ScrollTopLink className="btn btn-outline-secondary ml-2" to={url('/about')}>
            <FormattedMessage id="navbar.links.about" />
          </ScrollTopLink>
          <Link className="btn btn-outline-secondary ml-2" to={{pathname: url('/'), hash: 'home-contact'}}>
            <FormattedMessage id="navbar.links.contact" />
          </Link>
          <a className="btn btn-outline-secondary ml-2" {...anchor('http://blog.raks.com.br')}>
            Blog
          </a>
          {intl.locale === 'en' && (
            <Link className="btn btn-outline-secondary ml-2" to={languageUrl('pt')}>
              <Flag country="br" /> <span className="d-none d-md-inline-block">Português</span>
            </Link>
          )}
          {intl.locale === 'pt' && (
            <Link className="btn btn-outline-secondary ml-2" to={languageUrl('en')}>
              <Flag country="us" /> <span className="d-none d-md-inline-block">English</span>
            </Link>
          )}
        </Container>
      </nav>
    </div>
  )
}