import React, { FC, Fragment, MouseEvent, createRef, useLayoutEffect } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'

import { Col, Row, IconArrowDownShort } from 'app/components/bootstrap'
import { Navbar } from 'app/components/navbar'

import { Cases } from './components/Cases'
import { Contact } from './components/Contact'

import classes from './Home.module.scss'

export const Home: FC = () => {
  const { hash } = useLocation()
  const contact = createRef<HTMLDivElement>()
  const content = createRef<HTMLDivElement>()
  const handleDownButton = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    if (content.current) {
      const { top } = content.current.getBoundingClientRect()
      window.scroll({
        left: 0,
        top: top,
        behavior: 'smooth'
      })
    }
  }
  useLayoutEffect(() => {
    if (hash) {
      const element = document.querySelector(hash) as HTMLDivElement || {}
      if ('offsetTop' in element) {
        const navbar = document.getElementById('main-navbar')?.getBoundingClientRect()
        const options: ScrollToOptions = {
          left: 0,
          top: element.offsetTop,
          behavior: 'smooth'
        }
        if (navbar) {
          options.top! -= navbar.height
        }
        window.scroll(options)
      }
    }
  }, [hash])
  return (
    <Fragment>
      <Helmet />
      <Navbar />
      <div className={classes.banner}>
        <div className={classes.title}>
          <Row maxWidth>
            <Col md={4} lg={5}>
              <img
                className={classes.system}
                src="/img/sistema@3x.png"
                srcSet="/img/sistema@1x.png, /img/sistema@2x.png 2x, /img/sistema@3x.png 3x"
                alt="Foto do Sistema" />
            </Col>
            <Col className={classes.company} md={8} lg={7}>
              <img className={classes.logo} src={`/img/raks-en.svg`} alt="Raks Logo" />
              <h1>
                <FormattedMessage id="banner.title" />
              </h1>
              <div className="mb-4 pb-4 text-center">
                <Link className="btn btn-success btn-lg shadow-lg" to="/budget">
                  <FormattedMessage id="banner.button" />
                </Link>
              </div>
            </Col>
          </Row>
          <button type="button" className={classes.button} onClick={handleDownButton}>
            <span className={classes.more}>
              <FormattedMessage id="banner.more" />
            </span>
            <span className={classes.arrow}>
              <IconArrowDownShort />
            </span>
          </button>
        </div>
      </div>
      <div id="home-product" ref={content} className={`container ${classes.content}`}>
        <h3 className={classes.title}>
          <FormattedMessage id="home.product.title" />
        </h3>
        <div className="row">
          <div className="col-md-5">
            <img className={classes.sensor}
              src="/img/sensor@3x.jpg"
              srcSet="/img/sensor@1x.jpg, /img/sensor@2x.jpg 2x, /img/sensor@3x.jpg 3x"
              alt="Sistema" />
          </div>
          <div className="col-md-7 d-flex flex-column align-items-center">
            <div className="row">
              <div className={`col-3 ${classes.image}`}>
                <div>
                  <div>
                    <img 
                      src="/img/product-solar@3x.jpg"
                      srcSet="/img/product-solar@1x.jpg, /img/product-solar@2x.jpg 2x, /img/product-solar@3x.jpg 3x"
                      alt="Painel Solar" />
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className={classes.title}>
                  <FormattedMessage id="content.cards.placa.title" />
                </div>
                <div className={classes.description}>
                  <FormattedMessage id="content.cards.placa.description" />
                </div>
              </div>
              <div className={`col-3 ${classes.image}`}>
                <div>
                  <div>
                    <img 
                      src="/img/product-measurer@3x.jpg"
                      srcSet="/img/product-measurer@1x.jpg, /img/product-measurer@2x.jpg 2x, /img/product-measurer@3x.jpg 3x"
                      alt="Medidor" />
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className={classes.title}>
                  <FormattedMessage id="content.cards.medidor.title" />
                </div>
                <div className={classes.description}>
                  <FormattedMessage id="content.cards.medidor.description" />
                </div>
              </div>
              <div className={`col-3 ${classes.image}`}>
                <div>
                  <div>
                    <img
                      src="/img/product-system@3x.jpg"
                      srcSet="/img/product-system@1x.jpg, /img/product-system@2x.jpg 2x, /img/product-system@3x.jpg 3x"
                      alt="Sistema" />
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className={classes.title}>
                  <FormattedMessage id="content.cards.acompanhamento.title" />
                </div>
                <div className={classes.description}>
                  <FormattedMessage id="content.cards.acompanhamento.description" />
                </div>
              </div>
              <div className={`col-3 ${classes.image}`}>
                <div>
                  <div>
                    <img 
                      src="/img/product-sensor@3x.jpg"
                      srcSet="/img/product-sensor@1x.jpg, /img/product-sensor@2x.jpg 2x, /img/product-sensor@3x.jpg 3x"
                      alt="Sensor"
                      style={{objectFit: 'contain'}} />
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className={classes.title}>
                  <FormattedMessage id="content.cards.sensor.title" />
                </div>
                <div className={classes.description}>
                  <FormattedMessage id="content.cards.sensor.description" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="h1 mt-4 mb-3 text-center">
        <FormattedMessage id="home.cases.title" />
      </p>
      <div className={classes.video}>
        <Cases />
      </div>
      <div id="home-contact" ref={contact}>
        <Contact />
      </div>
    </Fragment>
  )
}
