import React from 'react'

import { Col, Row } from 'app/components/bootstrap'

import { LazyFrame } from './LazyFrame'

import classes from './Cases.module.scss'

export const Cases: React.FC = () => {
  return (
    <Row>
      <Col md={8} lg={7} xl={5} className="mx-auto">
        <div className={classes.col}>
          <div className={classes.container}>
            <LazyFrame preview="/img/cases/montebelo.jpg" video="KyXl0ENyAwM" title="Raks - Agro Montebelo" />
          </div>
        </div>
      </Col>
      <Col md={8} lg={7} xl={5} className="mx-auto">
        <div className={classes.col}>
          <div className={classes.container}>
            <LazyFrame preview="/img/cases/entrevista.jpg" video="a2UgS7OfPzo" title="Raks - Entrevista" />
          </div>
        </div>
      </Col>
    </Row>
  )
}