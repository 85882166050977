import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { IMaskInput } from 'react-imask'

import { useFormGroupContext } from './FormGroup'

interface InputProps {
  mask?: 'phone' | null
  name: string
  onChange?: (_: {[key: string]: string}) => void
  required?: boolean
  type?: string
}

export const Input: React.FC<InputProps> = ({mask = null, name, onChange, ...props}) => {
  const context = useFormGroupContext()
  const input = useRef<HTMLInputElement>(null)
  const intl = useIntl()
  const [value, setValue] = useState('')
  const params = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.({[name]: event.target.value})
      setValue(event.target.value)
    },
    id: context.id,
    name: name,
    placeholder: context.placeholder && intl.formatMessage({id: context.placeholder}),
    ref: input,
    value: value
  }
  if (mask === 'phone' && intl.locale === 'pt') {
    const params = {
      inputRef: (element: HTMLInputElement) => {
        element.pattern = '\\([0-9]{2}\\) [0-9]{4,5}-[0-9]{4}'
      },
      mask: '(00) [0]0000-0000',
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.({[name]: event.target.value})
        setValue(event.target.value)
      },
      placeholder: context.placeholder && intl.formatMessage({id: context.placeholder}),
      value: value
    }
    return (
      <div className="col-sm-9">
        <IMaskInput className="form-control" {...input} {...params} {...props} />
      </div>  
    )
  }
  return (
    <div className="col-sm-9">
      <input className="form-control" {...params} {...props} />
    </div>
  )
}