import React, { FC } from 'react'

import { classNames } from 'app/util'

interface ContainerProps {
  className?: string
  maxWidth?: boolean
}

export const Container: FC<ContainerProps> = ({className, children, maxWidth}) => (
  <div className={classNames('container', className)}>
    {children}
  </div>
)