import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, LinkProps, useRouteMatch } from 'react-router-dom'

import { Col, Row } from 'app/components/bootstrap'
import { FaIcon } from 'app/components/icon'

import classes from './Footer.module.scss'

const ScrollTopLink: FC<LinkProps> = props => {
  const handleClick = () => window.scroll({top: 0, behavior: 'smooth'})
  return (
    <Link onClick={handleClick} {...props} />
  )
} 

export const Footer: FC = () => {
  const match = useRouteMatch()
  const url = (url: string) => `${match.url}${url}`.replace('//', '/')
  const anchor = (url: string) => ({
    href: url,
    rel: 'noopener noreferrer',
    target: '_blank'
  })
  const social = (url: string) => ({
    className: `btn ${classes.social}`,
    ...anchor(url)
  })
  return (
    <footer className={classes.footer}>
      <Row maxWidth>
        <Col className={classes.col} md={2} xl={3}>
          <img className={classes.logo} src="/img/raks-en.svg" alt="Raks Logo" />
        </Col>
        <Col className={classes.col} md={4}>
          <div className={classes.networks}>
            <a {...social('https://www.facebook.com/raksagro')}>
              <FaIcon icon="facebook" />
            </a>
            <a {...social('https://www.instagram.com/raksagro')}>
              <FaIcon icon="instagram" />
            </a>
            <a {...social('https://www.linkedin.com/company/raks-tecnologia-agricola')}>
              <FaIcon icon="linkedin" />
            </a>
            <a {...social('https://wa.me/5551991919438')}>
              <FaIcon icon="whatsapp" />
            </a>
          </div>
          <div className={classes.contact}>
            <FaIcon icon="envelope" />
            <span>contato@raks.com.br</span>
          </div>
        </Col>
        <Col className={classes.col} md={3}>
          <ScrollTopLink to={url('/')} className={classes.link}>
            <FormattedMessage id="footer.links.home" />
          </ScrollTopLink>
          <ScrollTopLink to={url('/budget')} className={classes.link}>
            <FormattedMessage id="footer.links.budget" />
          </ScrollTopLink>
          <Link to={{pathname: url('/'), hash: 'home-contact'}} className={classes.link}>
            <FormattedMessage id="footer.links.contact" />
          </Link>
          <ScrollTopLink to={url('/about')} className={classes.link}>
            <FormattedMessage id="footer.links.about" />
          </ScrollTopLink>
          <ScrollTopLink to={url('/work')} className={classes.link}>
            <FormattedMessage id="footer.links.work" />
          </ScrollTopLink>
        </Col>
        <Col className={classes.col} md={2} xl={2}>
          <img className={classes.selo} src="/img/greentech.png" alt="Greentech" />
        </Col>
      </Row>
      <p className={classes.copyright}>
        © Raks Tecnologia Agrícola. <FormattedMessage id="footer.copyright" />
      </p>
    </footer>
  )
}