import React, { FC } from 'react'

import { classNames } from 'app/util'

interface RowProps {
  className?: string
  maxWidth?: boolean
}

export const Row: FC<RowProps> = ({className, children, maxWidth}) => (
  <div className={maxWidth ? 'container' : 'container-fluid'}>
    <div className={classNames('row', className)}>
      {children}
    </div>
  </div>
)