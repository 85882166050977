
const translations = {
  en: {
    banner: {
      title: 'Smart Irrigation Control System',
      button: 'ASK FOR A QUOTE',
      more: 'View more'
    },
    home: {
      product: {
        title: 'PRODUCE MORE FOOD WITH LESS RESOURCES'
      },
      cases: {
        title: 'WHAT OUR CLIENTS ARE SAYING'
      }
    },
    content: {
      cards: {
        placa: {
          title: 'Solar Panels',
          description: 'Photovoltaic panels which capture solar energy to power the whole system, avoiding the need for cables on the field and expenses with electricity. This panel allows the system to be set on the field, having clean and renewable energy.'
        },
        medidor: {
          title: 'Measuring Device',
          description: 'An electronic circuit internally developed to measure the soil’s moisture and send the information through wireless communication, without internet and cables on the farmland.'
        },
        acompanhamento: {
          title: 'Platform',
          description: 'A platform to visualize all the information collected on the field and help the farmer on the decision making process. The measurements are easily shown, making use of graphs and tables, allowing the farmer to generate reports and detailed analysis.'
        },
        sensor: {
          title: 'Sensor',
          description: 'The soil’s moisture measurement is done by high precision sensors fixed on the field, independently of the soil’s characteristics. The sensors make use of TDR (Time Domain Reflectometry) technology to detect small changes on the soil’s moisture and it was totally developed by Raks ®.'
        }
      }
    },
    contact: {
      title: 'TALK TO US',
      submit: 'Send',
      text_1: 'Fill the form and send your message.',
      text_2: 'We will answer as soon as possible!',
      name: {
        label: 'Name:',
        placeholder: 'John Doe'
      },
      email: {
        label: 'E-mail:',
        placeholder: 'doe@example.com'
      },
      phone: {
        label: 'Phone number:',
        placeholder: '515-123-4567'
      },
      message: {
        label: 'Message:',
        placeholder: ''
      }
    },
    about: {
      title: 'ABOUT US',
      story: 'OUR STORY',
      paragraph_first: 'Looking at the world with curiosity, behind new challenges, Raks was born of the desire to transform. Desire that has come true through a project thought by people who believe that technology and knowledge must be applied to create solutions that impact Society, which facilitate and contribute positively to people\'s lives.',
      paragraph_second: 'Anchored in the pillars of sustainability, innovation and efficiency, we seek to minimize the problems experienced by the farmer. We seek to improve productivity in the agricultural sector and reduce wasted resources by working today to change tomorrow.',
      founders: {
        title: 'FOUNDERS',
        fabiane: 'Electronics Technician, Computer Science student and nerd. She lives by questioning the why of things and believes that the questions move the world. Determined, stubborn and research lover, she wants to have some impact on society. Her dream is to travel the world and contribute positively in people\'s lives, using technology as a tool of transformation.',
        guilherme: 'Electronics Technician, engineering student and technology lover. He believes that knowledge must be used to create solutions and improve people\'s lives. He is super competitive, likes to work late and make funning jokes. He is a lover of cars and races, he dreams of riding on great tracks in the world.',
        vinicius: 'Electronics Technician and computer engineering student. He has been an athlete and musician and is currently a lover of arts and science. Since childhood he has been interested in finding out how everything works and now applies all his curiosity and competitiveness in new challenges. He is the number one competitor of Guilherme as the worst joker and dreams of traveling the world.',
        sauer: 'Electrical Engineer, teacher and father of Lucas and Daniela. Since childhood he dreamed of being an engineer. Stubborn, he believes technology should be applied to make life easier. He loves aeromodelling, builds and flies his own model airplanes.'
      },
      team: {
        title: 'TEAM',
        robson: 'Administrator, lover of life and the moments it provides. He loves sports and being in touch with nature constantly. He strongly believes that people need each other to survive and technology in this area is a facilitator, because where there is a need, there is an opportunity. Being with the people he loves is his favorite hobby. His greatest ambition is to be able to make a positive difference in people\'s lives.',
        rodrigo: 'An electronic technician and an electronic engineering student, he is a technology lover. Curious and persistent, he likes to get to the bottom of things to understand them; he likes to be challenged and uses it as will power. Ambitious, he wants to make a difference in the world and be recognized for it.',
        gustavo: 'Electronics Technician, student of Automation and Control Engineering, passionate about cars and technology. He believes that technologies must be developed to improve, facilitate and accelerate the daily processes of people and companies. Sees Raks as an opportunity to develop technologies that will make a difference in society. He dreams of traveling the world and accomplishing important achievements through his work.',
        eduardo: 'Student of Computer Science, believes that tecnology should help with efficiency and productivity. Wishes to contribute with his knowledge to a better society, through tecnology innovations that makes people\'s life easier.',
        dionata: 'Agricultural technician, Agronomy student and passionate about agriculture, especially soil science. He likes very much to travel and go out into the field, observing the different production environments and the particularities of the nature of each place. He believes that to share knowledge is to multiply it, so he tries to apply the knowledge acquired. He dreams being a farmer.',
        wagner: 'Growth Hacker and Francisco\'s father, likes farms and poetry. Curious and with crazy ideas, he likes to test as much as possible and believes that against data there are no arguments. He found at Raks the junction between the field and Growth Hacking, and it was love at first sight.',
        eduarda: 'UX Designer with a background in Urban Planning and Architecture, determined to facilitate communication between product and users. She believes in collaborative work and is always ready to do some cool volunteering, such as dig a fossil, built cob houses, or take care of a shepherd dog farm in a foreign country. She loves to travel and is obsessed with dogs.'
      },
      mentors: {
        title: 'MENTORS'
      },
      awards: {
        title: 'PRIZES & AWARDS'
      },
      partnerships: {
        title: 'PARTNERSHIPS'
      }
    },
    budget: {
      text_1: 'Please fill the form to provide us information to give you a quote.',
      text_2: 'We\'ll answer you as soon as possible!',
      form: {
        name: {
          label: 'Name:',
          placeholder: 'John Doe'
        },
        phone: {
          label: 'Phone number:',
          placeholder: '515-123-4567'
        },
        email: {
          label: 'E-mail:',
          placeholder: 'doe@example.com'
        },
        city: {
          label: 'City:',
          placeholder: 'City where the system will be installed'
        },
        crop: {
          label: 'Cultura:',
          placeholder: 'Crop type (e.g. corn)'
        },
        farm_size: {
          label: 'Farm size:',
          placeholder: ''
        },
        extra: {
          label: 'Extra:',
          placeholder: 'Here you can put some extra informations that you think we should know before giving you a quote'
        },
        submit: 'Place request',
        success: 'Request sent successfully!'
      }
    },
    work: {
      title: 'Work With Us',
      paragraph_first: 'Want to be part of our history?',
      paragraph_second: 'Send information about yourself and stay tuned in our opportunities!',
      form: {
        name: {
          label: 'Name:',
          placeholder: 'John Doe'
        },
        email: {
          label: 'E-mail:',
          placeholder: 'doe@example.com'
        },
        phone: {
          label: 'Phone number:',
          placeholder: '515-123-4567'
        },
        area: {
          label: 'Area of interest:',
          placeholder: 'Your work area'
        },
        linkedin: {
          label: 'LinkedIn profile:',
          placeholder: 'Link of your LinkedIn profile'
        },
        curriculum: {
          label: 'Curriculum:',
          placeholder: ''
        }
      }
    },
    footer: {
      links: {
        about: 'About Us',
        budget: 'Ask for a Quote',
        contact: 'Contact',
        home: 'Home',
        work: 'Work with Us'
      },
      copyright: 'All rights reserved.'
    },
    form: {
      file: {
        browse: 'Choose file'
      }
    },
    navbar: {
      links: {
        product: 'Product',
        about: 'About Us',
        contact: 'Contact'
      }
    }
  },
  pt: {
    banner: {
      title: 'Sistema Inteligente para Controle da Irrigação',
      button: 'PEDIR UM ORÇAMENTO',
      more: 'Saiba mais'
    },
    home: {
      product: {
        title: 'PRODUZA MAIS ALIMENTOS COM MENOS RECURSOS'
      },
      cases: {
        title: 'NOSSOS CASOS DE SUCESSO'
      }
    },
    content: {
      cards: {
        placa: {
          title: 'Placas Solares',
          description: 'Placa que capta a energia solar e alimenta todo o sistema, evitando que sejam necessários cabos em campo e gastos com energia elétrica. Essa placa permite que todo o sistema fique fixo e ainda conte com energia limpa e renovável.'
        },
        medidor: {
          title: 'Medidor',
          description: 'Circuito eletrônico desenvolvido internamente para interpretar as informações de umidade do solo e realizar a transmissão de dados via conexão sem fio, sem internet e sem cabos no meio da lavoura.'
        },
        acompanhamento: {
          title: 'Plataforma',
          description: 'Plataforma para visualização de todas as informações coletadas e auxilio na tomada de decisão. As medidas são apresentadas de forma simples, através de gráficos e tabelas, permitindo geração de relatórios e análises detalhadas.'
        },
        sensor: {
          title: 'Sensor',
          description: 'A medição da umidade do solo é realizada através de sensores fixos em campo com alta precisão independentemente do tipo de solo. Os sensores utilizam tecnologia TDR para verificar pequenas mudanças nos valores e foram totalmente desenvolvidos pela Raks ®.'
        }
      }
    },
    contact: {
      title: 'CONVERSE CONOSCO',
      submit: 'Enviar',
      text_1: 'Preencha as informações do formulário e envie sua mensagem.',
      text_2: 'Responderemos o mais rápido possível!',
      name: {
        label: 'Nome:',
        placeholder: 'Digite seu nome'
      },
      email: {
        label: 'E-mail:',
        placeholder: 'Digite seu e-mail'
      },
      phone: {
        label: 'Telefone:',
        placeholder: '(51) 98765-1234'
      },
      message: {
        label: 'Mensagem:',
        placeholder: 'Digite sua mensagem'
      }
    },
    about: {
      title: 'SOBRE NÓS',
      story: 'NOSSA HISTÓRIA',
      paragraph_first: 'Olhando para o mundo com curiosidade, atrás de novos desafios, a Raks nasceu do desejo de transformar. Desejo esse que se tornou realidade através de um projeto pensado por pessoas que acreditam que a tecnologia e o conhecimento devem ser aplicados para criar soluções que causem impacto na sociedade, que facilitem e contribuam positivamente na vida das pessoas.',
      paragraph_second: 'Ancorados nos pilares de sustentabilidade, inovação e eficiência, buscamos minimizar os problemas encontrados pelo profissional do campo. Procuramos melhorar a produtividade no setor agrícola e reduzir o desperdício de recursos, trabalhando hoje para mudar o amanhã.',
      founders: {
        title: 'FUNDADORES',
        fabiane: 'Técnica em Eletrônica, estudante de Ciência da Computação e nerd. Vive questionando o porquê das coisas e acredita que as perguntas movem o mundo. Determinada, teimosa e amante de pesquisa, deseja causar algum impacto na sociedade. Seu sonho é viajar o mundo e contribuir positivamente na vida das pessoas, usando a tecnologia como ferramenta de transformação.',
        guilherme: 'Técnico em Eletrônica, estudante de engenharia e apaixonado por tecnologia. Acredita que os conhecimentos devem ser utilizados para criar soluções e melhorar a vida das pessoas. É super competitivo, gosta de trabalhar até tarde e de fazer piadas sem graça. É amante de carros e corridas, sonha em pilotar em grandes pistas do mundo.',
        vinicius: 'Técnico em Eletrônica e estudante de engenharia da computação. Já foi um pouco esportista e músico e atualmente é apaixonado por artes e ciência. Desde pequeno foi interessado em descobrir como tudo funciona e agora aplica toda sua curiosidade e competitividade em novos desafios. É rival número um do Guilherme em pior piadista e sonha em viajar o mundo.',
        sauer: 'Engenheiro Eletricista, professor e pai do Lucas e da Daniela. Desde pequeno sonhou em ser engenheiro. Teimoso, acredita que a tecnologia deve ser aplicada para facilitar a vida das pessoas. É apaixonado por aeromodelismo, constrói e voa seus próprios aeromodelos.'
      },
      team: {
        title: 'TIME CAMPEÃO',
        robson: 'Administrador formado, amante da vida e dos momentos que ela proporciona. Apaixonado por esportes, adora estar em contato com a natureza constantemente. Acredita muito que as pessoas carecem umas das outras para sobreviverem e a tecnologia nesse âmbito é um facilitador, pois onde onde há uma necessidade, há oportunidades. Estar com as pessoas que ama é seu hobby preferido. Sua maior ambição é poder fazer a diferença de forma positiva na vida das pessoas.',
        rodrigo: 'Técnico em eletrônica, estudante de engenharia eletrônica, é um apaixonado por tecnologia. Curioso e persistente, gosta de ir a fundo nas coisas para buscar entendê-las; gosta de ser desafiado e usa isso como motivação. Ambicioso, quer fazer a diferença no mundo e ser reconhecido por isso.',
        gustavo: 'Técnico em Eletrônica, estudante de Engenharia de Controle e Automação, apaixonado por carros e tecnologia. Acredita que tecnologias devem ser desenvolvidas para aperfeiçoar, facilitar e acelerar os processos do dia-a-dia de pessoas e empresas. Vê na Raks a oportunidade de desenvolver tecnologias que farão a diferença na sociedade. Sonha em viajar o mundo e realizar feitos importantes através do seu trabalho.',
        eduardo: 'Estudante de Ciência da Computação, acredita que a tecnologia deve auxiliar na eficiência e produtividade. Deseja contribuir com seus conhecimentos para uma sociedade melhor, através de inovações tecnológicas que facilitem a vida das pessoas.',
        dionata: 'Técnico em Agropecuária, estudante de Agronomia e apaixonado pela agricultura, especialmente pela ciência do solo. Gosta muito de viajar e sair para a lavoura, observando os diferentes ambientes de produções e as particularidades da natureza de cada lugar. Acredita que dividir o conhecimento é multiplicá-lo, por isso procura aplicar o conhecimento adquirido. Sonha em ser produtor rural.',
        wagner: 'Growth Hacker e pai do Francisco, gosta do campo e de poesia. Curioso e com ideias malucas, gosta de testar o máximo possível e acredita que contra dados não existem argumentos. Encontrou na Raks a junção entre o campo e o Growth Hacking, e foi amor à primeira vista.',
        eduarda: 'UX Designer com background em Arquitetura e Urbanismo, concentrada em facilitar a comunicação entre produto e usuários. Ela acredita no trabalho colaborativo e está sempre pronta para fazer algum voluntariado legal, como escavar um fóssil, construir casas de adobe ou cuidar de uma fazenda de cães pastores em um país estrangeiro. Ela adora viajar e é obcecada por cachorros.'
      },
      mentors: {
        title: 'MENTORES'
      },
      awards: {
        title: 'PREMIAÇÕES'
      },
      partnerships: {
        title: 'PARCERIAS'
      }
    },
    budget: {
      text_1: 'Precisamos de algumas informações para montar um orçamento.',
      text_2: 'Iremos retornar o mais rápido possível!',
      form: {
        name: {
          label: 'Nome:',
          placeholder: 'José Pereira'
        },
        phone: {
          label: 'Telefone:',
          placeholder: '(51) 98765-1234'
        },
        email: {
          label: 'E-mail:',
          placeholder: 'exemplo@dominio.com.br'
        },
        city: {
          label: 'Cidade:',
          placeholder: 'Município onde será instalado'
        },
        crop: {
          label: 'Cultura:',
          placeholder: 'Cultura'
        },
        farm_size: {
          label: 'Tamanho:',
          placeholder: 'Tamanho da propriedade em hectares'
        },
        extra: {
          label: 'Observações:',
          placeholder: 'Digite aqui todas as informações extras que julgue relevante para a elaboração do orçamento'
        },
        submit: 'Encaminhar solicitação de orçamento',
        success: 'Informações enviadas com sucesso!'
      }
    },
    work: {
      title: 'TRABALHE CONOSCO',
      paragraph_first: 'Quer fazer parte dessa história?',
      paragraph_second: 'Cadastre-se em nossa base de talentos e fique por dentro das oportunidades!',
      form: {
        name: {
          label: 'Nome:',
          placeholder: 'José Pereira'
        },
        email: {
          label: 'E-mail:',
          placeholder: 'exemplo@dominio.com.br'
        },
        phone: {
          label: 'Telefone:',
          placeholder: '(51) 98765-1234'
        },
        area: {
          label: 'Área de Interesse:',
          placeholder: 'Digite suas áreas de interesse'
        },
        linkedin: {
          label: 'Perfil do LinkedIn:',
          placeholder: 'Link do perfil'
        },
        curriculum: {
          label: 'Currículo:',
          placeholder: 'Currículo'
        }
      }
    },
    footer: {
      links: {
        about: 'Sobre',
        budget: 'Pedir Orçamento',
        contact: 'Contato',
        home: 'Início',
        work: 'Trabalhe Conosco'
      },
      copyright: 'Todos os direitos reservados.'
    },
    form: {
      file: {
        browse: 'Selecionar arquivo'
      }
    },
    navbar: {
      links: {
        product: 'Produto',
        about: 'Sobre',
        contact: 'Contato'
      }
    }
  }
}

type Unflattened<T> = {
  [key: string]: T | Unflattened<T>
}

type Flattened<T> = {[key: string]: T}
type Reducer<T> = (prefix?: string) => (current: Flattened<T>, entries: [string, Unflattened<T> | T]) => Flattened<T>

function flatten<T = string>(translations: Unflattened<T>): Flattened<T> {
  const reducer: Reducer<T> = (start?: string) => (current, [key, translation]) => {
    const prefix = start || ''
    if (typeof translation === 'object' && translation !== null) {
      Object.entries(translation).reduce(reducer(`${prefix}.${key}`), current)
    } else {
      key = `${prefix}.${key}`.replace(/^\./, '')
      current[key] = translation
    }
    return current
  }
  return Object.entries(translations).reduce(reducer(), {})
}

export const messages = Object.fromEntries(
  Object.entries(translations).map(([key, translations]) => {
    return [key, flatten<string>(translations)]
  })
)
